import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Md5 } from 'ts-md5';
import { signInWithCustomToken } from 'firebase/auth';
import * as Sentry from '@sentry/vue';
import mixpanel from 'mixpanel-browser';
import unleash from '../unleash';
import { getApplicationIdAsync, getSlug } from '../api/slugApi';
import { auth } from '../firebase';
import getViteProxy from '../helpers/getViteProxy';
import getViteBff from '../helpers/getViteBff';

const BFF_ENDPOINT = `${getViteBff()}/app`;
const FUNC_ENDPOINT = getViteProxy();

const useUserStore = defineStore('user', () => {
  const roles = ref<string[]>([]);
  const email = ref<string | undefined>();
  const userId = ref<string | undefined>();
  const tid = ref<string | undefined>();
  const picture = ref<string>('https://www.gravatar.com/avatar/?d=identicon');
  const name = ref<string>('Non connecté');

  async function authFirebase(): Promise<boolean> {
    const response = await fetch(`${FUNC_ENDPOINT}/misc-auth`, {
      credentials: 'include',
    });

    if (response.status === 200) {
      await signInWithCustomToken(auth, await response.text());
      return true;
    }
    return false;
  }

  let timeoutID: NodeJS.Timeout | undefined;
  async function autoRefresh(): Promise<void> {
    if (timeoutID) clearTimeout(timeoutID);

    const cookie = document.cookie.split('; ').find((row) => row.startsWith('app.at_exp'));

    if (cookie) {
      const cookieSplit = cookie.split('=');
      const date = new Date(+cookieSplit[1]);

      // Add auto refresh 5 minutes before expiration
      date.setMinutes(date.getMinutes() - 5);

      if (date > new Date()) {
        timeoutID = setTimeout(async () => {
          const response = await fetch(`${BFF_ENDPOINT}/refresh`, {
            credentials: 'include',
          });

          if (response.ok) autoRefresh();
        }, date.getTime() - new Date().getTime());
      } else {
        const response = await fetch(`${BFF_ENDPOINT}/refresh`, {
          credentials: 'include',
        });

        if (response.ok) autoRefresh();
      }
    }
  }

  async function fetchUser(): Promise<boolean> {
    await autoRefresh();

    const result = await fetch(`${BFF_ENDPOINT}/me?slug=${getSlug()}`, {
      method: 'GET',
      credentials: 'include',

    });

    if (result.ok) {
      authFirebase();
      const json = await result.json();

      unleash.setContextField('userId', json.tid as string);
      await unleash.start();

      roles.value = json.roles;
      email.value = json.email;
      userId.value = json.sub;
      tid.value = json.tid;
      picture.value = `https://www.gravatar.com/avatar/${Md5.hashStr(json.email.trim().toLowerCase())}?d=identicon`;
      name.value = `${json.given_name} ${json.family_name}`;

      if (import.meta.env.PROD) {
        Sentry.setUser({
          id: json.sub,
          email: json.email,
          username: `${json.given_name} ${json.family_name}`,
          segment: json.tid,
        });
      }
      mixpanel.identify(json.sub);
      mixpanel.people.set({
        $email: json.email,
        $name: `${json.given_name} ${json.family_name}`,
      });

      return true;
    }
    return false;
  }

  async function login(): Promise<void> {
    try {
      const result = await getApplicationIdAsync(true);
      if (!result.teoappId) throw new Error('No application found');
      const { teoappId } = result;

      const query = new URLSearchParams({
        client_id: teoappId,
        redirect_uri: window.location.href,
        response_type: 'code',
        scope: 'openid offline_access',
        slug: getSlug()!,
      });

      window.location.replace(`${BFF_ENDPOINT}/login?${query.toString()}`);
    } catch (e: any) {
      if (e.status === 404) window.location.replace('https://auth.teoapp.fr?app=flo');
    }
  }

  function logout() {
    const query = new URLSearchParams({
      post_logout_redirect_uri:
        `${window.location.protocol
        }//${window.location.host
        }/${getSlug()}`,
    });

    window.location.replace(`${BFF_ENDPOINT}/logout?${query.toString()}`);
  }

  return {
    roles, email, picture, name, fetchUser, login, userId, logout, tid,
  };
});

export default useUserStore;
