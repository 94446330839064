import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import chorusModule, { ChorusState } from './Chorus';
import configModule, { ConfigurationState } from './Configuration';

export interface RootState {
  chorus: ChorusState;
  config: ConfigurationState;
}

// eslint-disable-next-line symbol-description
export const key: InjectionKey<Store<RootState>> = Symbol();

export const store = createStore<RootState>({
  modules: {
    chorus: chorusModule,
    config: configModule,
  },
});

export function useStore() {
  return baseUseStore(key);
}
