import getViteProxy from '../helpers/getViteProxy';
import { getAsync } from './apiHelper';

const endpoint = `${getViteProxy()}/misc-slug`;

interface SlugInfo {
  tenantId: string | undefined;
  teoappId: string | undefined;
  teocomptaId: string | undefined;
}

export function getSlug(): string | null {
  const matches = window.location.pathname.split('/');

  if (matches[1]?.length > 0) {
    localStorage.setItem('savedSlug', matches[1]);
  }

  return localStorage.getItem('savedSlug');
}

export async function getApplicationIdAsync(ignoreError?: boolean): Promise<SlugInfo> {
  const slug = getSlug();
  if (!slug) throw new Error('Slug not found');
  if (slug === 'demo') return {} as SlugInfo;
  return getAsync<SlugInfo>(`${endpoint}?slug=${slug}`, { credentials: 'omit' }, ignoreError);
}

export async function getTenant(): Promise<number> {
  const slug = getSlug();
  if (!slug) throw new Error('Slug not found');
  if (slug === 'demo') return 200;
  const resp = await fetch(`${endpoint}?slug=${slug}`, { credentials: 'omit' });
  return resp.status;
}
