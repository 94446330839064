enum FeatureFlags {
  AGENDA = 'neo_agenda',
  TEOFORM_TEMPLATE = 'neo_teoform_template',
  TEOFORM_RESPONSE = 'neo_teoform_response',
  PROCEDURE_ACTIVITIES = 'neo_procedure_activities',
  NEO_PREMIUM = 'neo_premium',
  NEO_ACTIVITY_DOSSIER_MANDATORY = 'neo_activity_dossier_mandatory',
}

export default FeatureFlags;
