<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';
import { onMounted } from 'vue';
import mixpanel from 'mixpanel-browser';
import LateralNav from './components/Navigation/LateralNav.vue';
import RightDrawer from './components/Shared/RightDrawer.vue';
import FlashMessage from './components/Shared/FlashMessage.vue';
import { useConfigurationStore } from './stores/configuration';
import useUserStore from './stores/user';
import { getSlug, getTenant } from './api/slugApi';
import Popup from './components/Shared/Popup/GlobalPopup.vue';

const route = useRoute();
const configurationStore = useConfigurationStore();
const store = useUserStore();

const toggleNav = () => {
  if (configurationStore.leftDrawer && window.innerWidth < 1280) configurationStore.toggleLeftDrawer(false);
};

const setViewHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

onMounted(async () => {
  setViewHeight();
  window.addEventListener('resize', () => {
    setViewHeight();
  });

  const router = useRouter();
  try {
    const result = await getTenant();
    if (result === 403) router.replace({ name: 'UpgradeLicense', params: { slug: getSlug() } });
    else if (result === 404) router.replace({ name: 'NotFound', params: { slug: getSlug() } });
    else if (result === 500) router.replace({ name: 'ErrorView', params: { slug: getSlug() } });
  } catch (e) {
    router.replace({ name: 'ErrorView', params: { slug: getSlug() } });
  }
  mixpanel.track('neo_access_app');
});

// Stop error resizeObserver
/* eslint-disable */
const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function (...args: any[]) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 20);
    super(callback);
  }
};
/* eslint-enable */
</script>

<template>
  <div class="flex h-screen bg-gray-appBackground items-stretch">
    <FlashMessage />
    <Popup />
    <LateralNav v-if="!route.query.hide && store.userId && !(route.name === 'ProcedureActivities' && route.params.id)" />
    <div
      class="flex grow items-stretch relative"
      @click="toggleNav()"
    >
      <div
        class="w-full shrink flex flex-col"
        :class="configurationStore.leftDrawer ? 'brightness-50 xl:brightness-100 pointer-events-none xl:pointer-events-auto' : ''"
      >
        <div class="grow h-0">
          <router-view v-slot="{ Component }">
            <transition
              name="fade"
              mode="out-in"
            >
              <component :is="Component" />
            </transition>
          </router-view>
          <div
            v-if="!store.userId"
            class="text-9xl flex justify-center items-center h-full"
          >
            <img
              src="./assets/logo_loading.gif"
              alt="loading"
              class="w-20"
            >
          </div>
        </div>
      </div>
      <RightDrawer />
    </div>
  </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.03s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Styles pour les listes provenant de TinyMCE */
.teoform-html-content ul,
[v-html] ul {
  list-style-type: disc !important;
  padding-left: 1.5rem !important;
  margin: 0.5rem 0 !important;
}

.teoform-html-content ol,
[v-html] ol {
  list-style-type: decimal !important;
  padding-left: 1.5rem !important;
  margin: 0.5rem 0 !important;
}

.teoform-html-content li,
[v-html] li {
  display: list-item !important;
  margin-bottom: 0.25rem !important;
}

/* Styles pour assurer que les listes avec role="list" sont correctement stylisées */
ul[role="list"],
ol[role="list"] {
  list-style-position: outside !important;
}
</style>
