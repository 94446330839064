import { defineStore } from 'pinia';
import { ref } from 'vue';

export type PopupType = 'error' | 'info' | 'warning';

export interface PopupMessage {
  message: string;
  duration?: number;
  type?: PopupType;
}

export const usePopupStore = defineStore('popup', () => {
  const isVisible = ref(false);
  const message = ref('');
  const type = ref<PopupType>('info');
  const timeoutId = ref<number | null>(null);

  function showPopup(popupMessage: PopupMessage) {
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
    }

    message.value = popupMessage.message;
    type.value = popupMessage.type || 'info';
    isVisible.value = true;

    const duration = popupMessage.duration || 3000;
    timeoutId.value = window.setTimeout(() => {
      isVisible.value = false;
      setTimeout(() => {
        message.value = '';
      }, 400);
    }, duration);
  }

  function hidePopup() {
    isVisible.value = false;
    message.value = '';
    type.value = 'info';
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
    }
  }

  return {
    isVisible,
    message,
    type,
    showPopup,
    hidePopup,
  };
});
