<script setup lang="ts">
import { TransitionRoot } from '@headlessui/vue';
import { computed } from 'vue';
import { usePopupStore } from '../../../stores/popup';
import PlusIcon from '../Icon/PlusIcon.vue';
import InfoIcon from '../Icon/InfoIcon.vue';
import AlertIcon from '../Icon/AlertIcon.vue';

const store = usePopupStore();

const bgColorClass = computed(() => {
  if (store.type === 'error') return 'bg-red-100';
  if (store.type === 'warning') return 'bg-amber-100';
  return 'bg-green-100';
});

const textColorClass = computed(() => {
  if (store.type === 'error') return 'text-red-800';
  if (store.type === 'warning') return 'text-amber-800';
  return 'text-green-800';
});
</script>

<template>
  <TransitionRoot
    :show="store.isVisible"
    as="div"
    enter="transition-opacity duration-300"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-300"
    leave-from="opacity-100"
    leave-to="opacity-0"
    class="fixed top-4 left-1/2 -translate-x-1/2 z-[100]"
  >
    <div :class="[bgColorClass, 'rounded-lg shadow-lg px-4 py-2 flex items-center gap-2']">
      <PlusIcon
        v-if="store.type === 'error'"
        class="h-5 w-5 text-red-600 rotate-45"
      />
      <AlertIcon
        v-else-if="store.type === 'warning'"
        class="h-5 w-5 text-amber-600"
      />
      <InfoIcon
        v-else
        class="h-5 w-5 text-green-600"
      />
      <p :class="[textColorClass, 'text-xs']">
        {{ store.message }}
      </p>
    </div>
  </TransitionRoot>
</template>
