import getViteBackend from '../../helpers/getViteBackend';
import getViteProxy from '../../helpers/getViteProxy';
import ChorusConfigurationRequest from '../../models/Chorus/ChorusConfigurationRequest';
import { setDemoChorusConfig } from '../../store/Demo/DemoChorusConfiguration';
import {
  getAsync, postAsync, putAsync, sleep,
} from '../apiHelper';
import { getSlug } from '../slugApi';

const endpoint = `${getViteBackend()}/api/choruspro/configurations`;
const chorusProxy = `${getViteProxy()}/chorus-suppliers`;
const chorusProSuppliers = `${getViteProxy()}/chorus-suppliers`;

export async function getAllChorusConfigAsync(pageNb: number): Promise<any> {
  if (getSlug() !== 'demo') return (await getAsync(`${endpoint}?page=${pageNb}`)) as any;
  return { 'hydra:member': [] };
}

export async function createChorusConfigAsync(model: ChorusConfigurationRequest): Promise<any> {
  if (getSlug() !== 'demo') return postAsync(endpoint, model);
  await sleep(300);
  setDemoChorusConfig([model]);
  return { configurationId: '0' };
}

export async function updateChorusConfigAsync(model: ChorusConfigurationRequest): Promise<any> {
  if (getSlug() !== 'demo') return putAsync(`${endpoint}/${model.id}`, model);
  await sleep(300);
  setDemoChorusConfig([model]);
  return '';
}

export async function getAllBankAccountAsync(): Promise<any> {
  if (getSlug() !== 'demo') {
    const response = (await getAsync(`${chorusProxy}?target=account`)) as any;
    const result: Array<any> = [];
    if (!response.listeCoordonneeBancaire) return [{ name: 'Aucune information bancaire disponible', value: null }];
    response.listeCoordonneeBancaire.forEach((e: any) => result.push({
      name: e.nomCoordoneeBancaire,
      value: e.idCoordonneeBancaire,
    }));
    return result;
  }
  await sleep(500);
  return [
    { name: 'Séléctionner une option', value: '' },
    { name: 'RIB Tahiti', value: 123 },
    { name: 'RIB Ile-de-France', value: 123 },
  ];
}

export async function getAllSuppliers(
  account: string,
  password: string,
): Promise<
  Array<{
    designationStructure: string;
    idStructureCPP: number;
    identifiant: string;
  }>
  > {
  if (getSlug() !== 'demo') {
    const response = (await postAsync(chorusProSuppliers, {
      account,
      password,
    })) as any;
    if (response.listeStructures) return response.listeStructures;
    return [];
  }
  await sleep(400);
  return [
    { designationStructure: 'Centre Tahiti', idStructureCPP: 123, identifiant: '44229377500031' },
    { designationStructure: 'Centre Ile-de-France', idStructureCPP: 123, identifiant: '44229377500031' },
  ];
}
